



















































































































































import { Component, Vue, PropSync, Prop, Watch } from "vue-property-decorator";
import store from "@/store";
// types
import { CustomExpressionData } from "@/types/CustomExpressionData";
import { ExtendedVessel } from "@/types/Vessel";
import { LogVariable } from "@/types/logVariable";

// components
import LogVariablesDialog from "@/components/widgets/LogVariablesDialog.vue";

// modules
import { getModule } from "vuex-module-decorators";
import LogVariablesModule from "@/store/clients/LogVariables.module";
import VesselsModule from "@/store/clients/Vessels.module";

const LogVariables = getModule(LogVariablesModule, store);
const Vessels = getModule(VesselsModule, store);

@Component({
  components: {
    LogVariablesDialog,
  },
})
export default class CustomExpressionSelect extends Vue {
  @PropSync("expression", { required: true }) syncExpression!: CustomExpressionData;
  @Prop({ required: true }) vesselId!: number;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: false }) isOperatorSelectAvailable!: boolean;
  @Prop({ default: false }) isCopyConditionAvailable!: boolean;

  // @Data
  operators = ["AND", "OR"];
  LogVariablesDialog = false;
  amount = 0;
  conditionsList: { text: string; symbol: string }[] = [
    {
      text: "Greater than",
      symbol: ">",
    },
    {
      text: "Less than",
      symbol: "<",
    },
    {
      text: "Greater than OR equal to",
      symbol: ">=",
    },
    {
      text: "Less than OR equal to",
      symbol: "<=",
    },
    {
      text: "Equal to",
      symbol: "=",
    },
  ];
  logVariablesColumn: any = {
    logVariables: [] as LogVariable[],
  };

  // @Watchers
  @Watch("syncExpression", { deep: true })
  onSyncExpressionChanged(): void {
    if (this.syncExpression.logVariable === null) this.onLogVariableInputClear();
    if (this.syncExpression.logVariable) this.logVariablesColumn.logVariables = [this.syncExpression.logVariable];
  }

  @Watch("logVariablesColumn", { deep: true })
  onLogVariableChanged(): void {
    Vue.set(this.syncExpression, "logVariable", this.logVariablesColumn.logVariables[0]);

    this.$emit("onExpressionChanged", this.syncExpression);
  }

  // @Getters
  get isVesselLogVariablesLoaded(): boolean {
    return Boolean(this.vesselLogVariables);
  }

  get vesselLogVariables(): LogVariable[] | undefined {
    return LogVariables.logVariableByVesselId(this.vesselId);
  }

  get selectedLogVariable(): LogVariable {
    return this.logVariablesColumn.logVariables[0] ?? ({} as LogVariable);
  }

  get selectedLogVariableName(): string {
    return this.selectedLogVariable?.displayName ?? this.selectedLogVariable?.name;
  }

  get selectedLogVariableUnitName(): string {
    return this.selectedLogVariable?.unit?.name;
  }

  get vessel(): ExtendedVessel | undefined {
    return Vessels.getExtendedVesselById(this.vesselId);
  }

  get isCopyConditionActive(): boolean {
    if (this.isOperatorSelectAvailable) {
      return Boolean(this.isCopyConditionAvailable && this.syncExpression.logVariable && this.syncExpression.symbol.length !== 0 && this.syncExpression.operator !== null);
    } else {
      return Boolean(this.isCopyConditionAvailable && this.syncExpression.logVariable && this.syncExpression.symbol.length !== 0);
    }
  }

  get isInputDisabled(): boolean {
    return this.disabled || this.hasMathExpression;
  }

  get hasMathExpression(): boolean {
    return Boolean(this.syncExpression.mathContent && this.syncExpression?.mathContent.length > 0);
  }

  get isOperatorDisabled(): boolean {
    return !this.isOperatorSelectAvailable || this.hasMathExpression;
  }

  content(mode: string | undefined = undefined): string | object {
    var expressionStructure = {
      type: "BinaryExpression",
      token: this.syncExpression.symbol,
      left: {
        type: "Identifier",
        token: this.syncExpression.logVariable?.id,
        left: null,
        right: null,
      },
      right: {
        type: "Literal",
        token: this.syncExpression.amount,
        left: null,
        right: null,
      },
    };
    return mode === "stringify" ? JSON.stringify(expressionStructure) : expressionStructure;
  }
  // @Methods

  onLogVariableInputClick(): void {
    this.LogVariablesDialog = true;
  }

  numericFilter(event: any): void {
    event = event ? event : window.event;
    const expect = event.target.value.toString() + event.key.toString();
    return !/^\d+$/.test(expect) ? event.preventDefault() : false;
  }

  onLogVariableInputClear(): void {
    this.logVariablesColumn.logVariables = [] as LogVariable[];
  }

  onConditionChanged($value: any): void {
    Vue.set(this.syncExpression, "content", this.content("stringify"));
    Vue.set(this.syncExpression, "expressionStructure", this.content());
    this.$emit("onExpressionChanged", this.syncExpression);
  }

  onCopyCondition(): void {
    this.$emit("onCopyConditionEvent");
  }

  onAmountChanged($value: any): void {
    if ($value === "") {
      $value = 0;
      (this.$refs.expressionAmount as any).internalValue = $value;
    }
    Vue.set(this.syncExpression, "content", this.content("stringify"));
    Vue.set(this.syncExpression, "expressionStructure", this.content());
    this.$emit("onExpressionChanged", this.syncExpression);
  }

  async fetchVesselLogVariables(): Promise<void> {
    if (!this.isVesselLogVariablesLoaded) await LogVariables.fetchAllLogVariablesByVesselId(this.vesselId);
  }

  async created(): Promise<void> {
    await this.fetchVesselLogVariables();
    if (this.syncExpression.logVariable) this.logVariablesColumn.logVariables.push(this.syncExpression.logVariable);
  }
}
