function getDurationUnitFromSeconds(durationInSeconds, options) {
    var durationInDays = durationInSeconds / 60 / 60 / 24;
    var durationInHours = durationInSeconds / 60 / 60;
    var durationInMinutes = durationInSeconds / 60;

    if (!options.disableDays && isValidInteger(durationInDays)) {
        return "days";
    }

    if (!options.disableHours && isValidInteger(durationInHours)) {
        return "hours";
    }

    if (!options.disableMinutes && isValidInteger(durationInMinutes)) {
        return "minutes";
    }

    return "seconds";
}

function getDurationValueFromSeconds(durationInSeconds, options) {
    var durationInDays = durationInSeconds / 60 / 60 / 24;
    var durationInHours = durationInSeconds / 60 / 60;
    var durationInMinutes = durationInSeconds / 60;

    if (!options.disableDays && isValidInteger(durationInDays)) {
        return durationInDays;
    }

    if (!options.disableHours && isValidInteger(durationInHours)) {
        return durationInHours;
    }

    if (!options.disableMinutes && isValidInteger(durationInMinutes)) {
        return durationInMinutes;
    }

    return durationInSeconds;
}

function getSecondsFromDurationValueAndUnit(value, unit) {
    switch (unit) {
    case "seconds":
        return value;
    case "minutes":
        return value * 60;
    case "hours":
        return value * 60 * 60;
    case "days":
        return value * 60 * 60 * 24;
    default:
        throw "Unexpected duration unit";
    }
}

//See: polyfill on
//https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/isInteger
function isInteger(value) {
    return typeof value === "number" && isFinite(value) && Math.floor(value) === value;
}

function isValidInteger(value) {
    if (!isInteger(value)) return false;
    return true;
}

export default {
    getDurationValueFromSeconds: function(seconds, options) {
        options = options || {};
        return getDurationValueFromSeconds(seconds, options);
    },
    getDurationUnitFromSeconds: function(seconds, options) {
        options = options || {};
        return getDurationUnitFromSeconds(seconds, options);
    },
    getSecondsFromDurationValueAndUnit: function(value, unit) {
        return getSecondsFromDurationValueAndUnit(value, unit);
    },
    isValidInteger: function(value) {
        return isValidInteger(value);
    },
};