













































import { Component, Vue, Prop, PropSync } from "vue-property-decorator";
// types
import { Threshold } from "@/types/condition";

@Component({
  components: {},
})
export default class SeverityTextFieldSelector extends Vue {
  @PropSync("thresholds") syncThresholds!: Threshold[];
  @PropSync("value") syncValue!: number;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ type: String, default: "If Speed Loss is Above" }) readonly text!: string;
  @Prop({ type: String, default: "Warning" }) readonly type!: string;
  @Prop({ type: String, default: "" }) readonly inputAppendIcon!: string;
  @Prop({ type: String, default: "" }) readonly suffix!: string;
  @Prop({ type: String, default: "100px" }) readonly maxWidth!: string;

  // @Data
  staticThresholds = {
    durationInSeconds: 60,
    severity: this.type,
  } as Threshold;
  selectedThreshold: null | Threshold = null;
  isDataSetted = false;

  // @Methods
  severityColor(severity: string): string {
    return severity === "Critical" ? "error" : "warning";
  }

  numericFilter(event: any): void {
    event = event ? event : window.event;
    const expect = event.target.value.toString() + event.key.toString();
    return !/^\d+$/.test(expect) ? event.preventDefault() : false;
  }

  setInitialData(): void {
    this.staticThresholds = {
      durationInSeconds: 60,
      severity: this.type,
      notificationConditionId: this.syncThresholds[0]?.notificationConditionId ?? 0,
    } as Threshold;
    this.isDataSetted = true;
  }

  //  @Hooks
  mounted(): void {
    this.setInitialData();
  }
}
