



















































































import { Vue, Component, Prop, PropSync, Watch } from "vue-property-decorator";
import store from "@/store";
//  types
import { ICustomExpressionItem, CustomExpressionData } from "@/types/CustomExpressionData";
import { LogVariable } from "@/types/logVariable";
//  components
import CustomExpressionSelect from "@/components/Notifications/CustomExpressionSelect.vue";
import MathematicalExpressionSelect from "@/components/Notifications/MathematicalExpressionSelect.vue";
//  modules
import { getModule } from "vuex-module-decorators";
import LogVariablesModule from "@/store/clients/LogVariables.module";

const LogVariables = getModule(LogVariablesModule, store);

@Component({
  components: {
    CustomExpressionSelect,
    MathematicalExpressionSelect,
  },
})
export default class CustomExpressionsDialog extends Vue {
  @PropSync("value", { required: true }) isModalActive!: boolean;
  @PropSync("maxWidth", { default: 1000 }) dialogWidth!: number;
  @Prop({ required: true, default: () => [] }) customExpressionItemsList!: ICustomExpressionItem[];
  @Prop() disabledItems!: number[];
  @Prop({ default: false }) grouped!: boolean;
  @Prop({ default: null }) editItemIndex!: number | null;
  @Prop({ default: false }) operatorSelectAvailable!: boolean;

  // @Watchers
  @Watch("isModalActive")
  onOpenModal(): void {
    if (this.isModalActive) {
      if (this.grouped) {
        this.expressionsList = JSON.parse(JSON.stringify(this.EmptyExpressionsList)) as CustomExpressionData[];
      } else {
        if (this.customExpressionItemsList.length) {
          this.expressionsList = JSON.parse(JSON.stringify(this.customExpressionItemsList[0].expressions)) as CustomExpressionData[];
        }
        this.currentExpressionType = this.expressionsList[0].expressionType === "Logical" ? this.expressionTypes[0] : this.expressionTypes[1];
      }
    }
  }

  @Watch("customExpressionItemsList", { deep: true })
  onCustomExpressionItemsListChanged(): void {
    if (this.grouped) return;
    if (this.customExpressionItemsList.length) {
      this.expressionsList = JSON.parse(JSON.stringify(this.customExpressionItemsList[0].expressions)) as CustomExpressionData[];
    }
  }

  @Watch("currentExpressionType", { deep: true })
  onCurrentExpressionTypeChanged(): void {
    if (this.grouped) {
      this.expressionsList = JSON.parse(JSON.stringify(this.EmptyExpressionsList)) as CustomExpressionData[];
    } else {
      if (this.customExpressionItemsList.length) {
        this.expressionsList = JSON.parse(JSON.stringify(this.customExpressionItemsList[0].expressions)) as CustomExpressionData[];
      }
    }
  }

  // @Data
  expressionsList: CustomExpressionData[] = [];
  expressionTypes = [
    { type: "CustomExpressionSelect", text: "Logical" },
    { type: "MathematicalExpressionSelect", text: "Mathematical" },
  ];
  currentExpressionType = { type: "CustomExpressionSelect", text: "Logical" };

  // @Getters
  get EmptyExpressionsList(): object[] {
    return this.customExpressionItemsList.map(item => {
      return {
        vesselName: item.vesselName,
        vesselId: item.vesselId,
        logVariable: null,
        amount: 0,
        symbol: "",
        operator: null,
        content: item.expressionType === "Mathematical" ? item.expressions[0]?.content : null,
        mathContent: item.expressionType === "Mathematical" ? item.expressions[0]?.mathContent : "",
        expressionType: this.expressionType,
      };
    });
  }

  isExpressionTypeSelectDisabled(): boolean {
    if (!this.grouped) {
      if (this.customExpressionItemsList[0].expressionType === "Mathematical") {
        return Boolean(
          this.customExpressionItemsList[0].expressions && this.customExpressionItemsList[0]?.expressions[0]?.mathContent && this.customExpressionItemsList[0]?.expressions[0]?.mathContent.length > 0
        );
      } else if (this.isOperatorSelectAvailable(this.customExpressionItemsList[0].vesselId)) {
        return true;
      } else {
        return Boolean(
          this.customExpressionItemsList[0].expressions &&
            this.customExpressionItemsList[0]?.expressions[0]?.logVariable != null &&
            this.customExpressionItemsList[0]?.expressions[0]?.content &&
            this.customExpressionItemsList[0]?.expressions[0]?.content.length > 0
        );
      }
    } else return false;
  }

  expressionSelectType(expressionType: string | undefined): string {
    if (this.grouped) return this.currentExpressionType.type;
    return expressionType === "Logical" ? "CustomExpressionSelect" : "MathematicalExpressionSelect";
  }

  isLogicExpressionApplied(vesselId: number | undefined): boolean {
    const expressionItem = this.customExpressionItemsList.find(item => item.vesselId === vesselId);
    return Boolean(expressionItem && expressionItem.expressions.length > 0 && expressionItem.expressions[0].expressionType === "Logical" && expressionItem.expressions[0].content != null);
  }

  get expressionType(): string {
    return this.currentExpressionType.text;
  }

  get isSaveDisabled(): boolean {
    //  Logical expression type
    if (this.currentExpressionType.type === "CustomExpressionSelect") {
      if (this.grouped) {
        if (this.customExpressionItemsList.some(item => item.expressions.length === 0 && item.expressionType === "Logical")) {
          return this.expressionsList.some(expr => {
            const initialExpressionItem = this.customExpressionItemsList.find(expression => expression.vesselId === expr.vesselId);
            return (
              (initialExpressionItem && initialExpressionItem.expressionType === "Logical" && expr.logVariable === null) ||
              (initialExpressionItem && initialExpressionItem.expressionType === "Logical" && expr.symbol.length === 0)
            );
          });
        }
        if (this.customExpressionItemsList.some(item => item.expressions.length >= 1)) {
          return this.expressionsList.some(expr => {
            const initialExpressionItem = this.customExpressionItemsList.find(expression => expression.vesselId === expr.vesselId);
            return (
              (initialExpressionItem && initialExpressionItem.expressionType === "Logical" && expr.logVariable === null) ||
              (initialExpressionItem && initialExpressionItem.expressionType === "Logical" && expr.symbol.length === 0) ||
              (initialExpressionItem && initialExpressionItem.expressionType === "Logical" && expr.operator === null)
            );
          });
        }
      }
      if (this.operatorSelectAvailable) {
        return (
          (this.editItemIndex != null && this.editItemIndex > 0 && this.expressionsList[0]?.operator === null) ||
          this.expressionsList[0]?.logVariable === null ||
          this.expressionsList[0]?.symbol.length === 0
        );
      } else {
        return this.expressionsList[0]?.logVariable === null || this.expressionsList[0]?.symbol.length === 0;
      }
      //  Mathematical expression type
    } else {
      if (this.grouped) return this.expressionsList.some(expr => expr.mathContent == null || expr.mathContent.length === 0);
      return this.expressionsList[0].mathContent == null || this.expressionsList[0].mathContent.length === 0;
    }
  }

  // @Methods
  open(): void {
    this.isModalActive = true;
    this.$emit("open");
  }

  close(): void {
    this.isModalActive = false;
    this.$emit("close");
    this.expressionsList = JSON.parse(JSON.stringify(this.EmptyExpressionsList)) as CustomExpressionData[];
  }

  save(): void {
    this.isModalActive = false;
    this.expressionsList = this.expressionType === "Logical" ? this.expressionsList.filter(exp => exp.logVariable && exp.symbol) : this.expressionsList.filter(exp => exp.content !== null);
    this.$emit("save", this.expressionsList);
    this.expressionsList = JSON.parse(JSON.stringify(this.EmptyExpressionsList)) as CustomExpressionData[];
  }

  onExpressionChanged(expression: CustomExpressionData, index: number): void {}

  onMathExpressionChanged(): void {
    this.expressionsList = JSON.parse(JSON.stringify(this.expressionsList)) as CustomExpressionData[];
  }

  onExpressionTypeChanged(): void {
    this.$emit("onTypeChanged", this.expressionType);
  }

  onCopyCondition(): void {
    this.expressionsList.forEach((exp, i) => {
      if (i === 0) return;
      const initialExpressionItem = this.customExpressionItemsList.find(expression => expression.vesselId === exp.vesselId);
      if (initialExpressionItem?.expressionType === "Mathematical" && initialExpressionItem.expressions.length) return;
      exp.amount = this.expressionsList[0].amount;
      exp.content = this.expressionsList[0].content;
      exp.logVariable = this.logVariableByNameAndVesselId(exp.vesselId ?? 0, this.expressionsList[0].logVariable?.displayName ?? "");
      exp.expressionStructure = JSON.parse(JSON.stringify(this.expressionsList[0].expressionStructure));
      exp.operator = this.expressionsList[0].operator;
      exp.symbol = this.expressionsList[0].symbol;
    });
  }

  isExpressionSelectDisabled(vesselId: number): boolean {
    if (!this.disabledItems || !this.disabledItems.length) return false;
    return this.disabledItems.includes(vesselId);
  }

  isOperatorSelectAvailable(vesselId: number | undefined): boolean {
    if (this.grouped) {
      if (!vesselId) return true;
      const expressionItem = this.customExpressionItemsList.find(item => item.vesselId === vesselId);
      return Boolean(expressionItem && expressionItem.expressions.length > 0);
    } else {
      return this.editItemIndex !== 0;
    }
  }

  isCopyConditionAvailable(index: number): boolean {
    return this.grouped && this.expressionsList.length > 1 && index === 0 ? true : false;
  }

  logVariableByNameAndVesselId(vesselId: number, name: string): LogVariable | null {
    return LogVariables.logVariableByNameAndVesselId(vesselId, name) ?? null;
  }
}
