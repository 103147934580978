













































import { Component, Vue, PropSync } from "vue-property-decorator";
import store from "@/store";
//  types
import { NotificationConditions } from "@/types/NotificationConditions";
import { ICustomExpressionItem, CustomExpressionData } from "@/types/CustomExpressionData";
import { ExtendedVessel } from "@/types/Vessel";
// components
import CustomExpressionItem from "@/components/Notifications/CustomExpressionItem.vue";
import CustomExpressionsDialog from "@/components/Notifications/CustomExpressionsDialog.vue";
//  modules
import { getModule } from "vuex-module-decorators";
import LogVariablesModule from "@/store/clients/LogVariables.module";

const LogVariables = getModule(LogVariablesModule, store);

@Component({
  components: {
    CustomExpressionItem,
    CustomExpressionsDialog,
  },
})
export default class CustomExpressionsModule extends Vue {
  @PropSync("notification", { required: true }) syncNotification!: NotificationConditions;
  // @Data
  CustomExpressionsDialog = false;
  addDescriptionDialog = false;
  customExpressionItemsList: ICustomExpressionItem[] = [];

  // @Getters
  get syncNotificationVessels(): ExtendedVessel[] {
    return this.syncNotification.vessels ?? [];
  }

  // @Methods
  setVesselsData(): void {
    if (this.syncNotification.vessels) {
      this.customExpressionItemsList = this.syncNotification.vessels?.map(v => {
        return {
          vesselId: v.id,
          vesselName: v.name,
          expressions: this.getExpressions(v.id, v.name),
          comment: this.getComment(v.id),
          expressionType: this.getExpressionType(v.id),
        };
      });
    }
  }

  onTypeChanged(data: { vesselId: number | null; type: string } | string): void {
    if (typeof data === "string") {
      this.customExpressionItemsList.forEach(e => {
        if (e.expressions.length) return;
        Vue.set(e, "expressionType", data);
      });
    } else {
      const expressionItemIndex = this.customExpressionItemsList.findIndex(item => item.vesselId === data.vesselId);
      if (expressionItemIndex !== -1) {
        Vue.set(this.customExpressionItemsList[expressionItemIndex], "expressionType", data.type);
        if (this.customExpressionItemsList[expressionItemIndex].expressions.length)
          this.customExpressionItemsList[expressionItemIndex].expressions.forEach(e => Vue.set(e, "expressionType", data.type));
      }
    }
  }

  onSaveCustomExpressions(expressions: CustomExpressionData[]): void {
    this.customExpressionItemsList.forEach((item, i) => {
      Vue.set(this.customExpressionItemsList[i], "expressions", [...item.expressions, ...expressions.filter(e => e.vesselId === item.vesselId)]);
    });
    this.$emit("onExpressionItemChanged", this.customExpressionItemsList);
  }

  onExpressionChanged(changedItem: { index: number; expression: CustomExpressionData }): void {
    const expressionItemIndex = this.customExpressionItemsList.findIndex(item => item.vesselId === changedItem.expression.vesselId);
    if (expressionItemIndex !== -1) this.customExpressionItemsList[expressionItemIndex].expressions.splice(changedItem.index, 1, changedItem.expression);
    this.$emit("onExpressionItemChanged", this.customExpressionItemsList);
  }

  onConditionDeleted(): void {
    this.$emit("onExpressionItemChanged", this.customExpressionItemsList);
  }

  onExpressionComment(expressionItemComment: { vesselId: number; comment: string | null }): void {
    this.$emit("onExpressionComment", expressionItemComment);
  }

  isOperatorSelectAvailable(vesselId: number | undefined): boolean {
    if (!vesselId) return true;
    const expressionItem = this.customExpressionItemsList.find(item => item.vesselId === vesselId);
    if (expressionItem) return expressionItem.expressions.length > 0;
    return true;
  }

  getExpressions(vesselId: number, vesselName: string): CustomExpressionData[] {
    if (this.syncNotification.conditions.length === 0) return [] as CustomExpressionData[];
    const vesselCondition = this.syncNotification.conditions.find(
      condition => condition.vesselId === vesselId && (condition.conditionType === "LogDataExpression" || condition.conditionType === "LogDataMathExpression")
    );
    if (vesselCondition == null) return [] as CustomExpressionData[];
    const splittedExpressionItem = vesselCondition.conditionDetails?.replace(/\r?\n|\r/g, "").split(/(?=\AND|\OR)/g) ?? ([] as string[]);
    const expressions = splittedExpressionItem.map((exp: string) => {
      const stringifiedExpression = exp
        .split(/(\<=|\>=|>|<|=|\AND|\OR)/g)
        .filter(item => item.length > 0)
        .map(item => item.trim());

      return {
        vesselId: vesselId,
        vesselName: vesselName,
        logVariable: LogVariables.logVariableByNameAndVesselId(vesselId, stringifiedExpression.length === 3 ? stringifiedExpression[0] : stringifiedExpression[1]) ?? null,
        amount: stringifiedExpression.length === 3 ? +stringifiedExpression[2].replace(/\D/g, "") : +stringifiedExpression[3]?.replace(/\D/g, "") ?? "",
        symbol: stringifiedExpression.length === 3 ? stringifiedExpression[1] : stringifiedExpression[2],
        operator: stringifiedExpression.length === 4 ? stringifiedExpression[0] : null,
        content: vesselCondition.content,
        mathContent: vesselCondition.conditionType === "LogDataMathExpression" ? vesselCondition.conditionDetails : "",
        expressionType: vesselCondition.conditionType === "LogDataExpression" || vesselCondition.conditionType == null ? "Logical" : "Mathematical",
      };
    });

    return expressions;
  }

  getComment(vesselId: number): string | null {
    if (!this.syncNotification.conditions.length) return null;
    const vesselCondition = this.syncNotification.conditions.find(
      condition => condition.vesselId === vesselId && (condition.conditionType === "LogDataExpression" || condition.conditionType === "LogDataMathExpression")
    );
    if (vesselCondition == null) return null;
    return vesselCondition.contentDescription ?? null;
  }

  getExpressionType(vesselId: number): string {
    if (!this.syncNotification.conditions.length) return "Logical";
    const vesselCondition = this.syncNotification.conditions.find(
      condition => condition.vesselId === vesselId && (condition.conditionType === "LogDataExpression" || condition.conditionType === "LogDataMathExpression")
    );
    if (vesselCondition == null) return "Logical";
    return vesselCondition.conditionType === "LogDataExpression" || vesselCondition.conditionType == null ? "Logical" : "Mathematical";
  }

  mounted(): void {
    if (this.syncNotification.vessels) {
      this.customExpressionItemsList = this.syncNotification.vessels?.map(v => {
        return {
          vesselId: v.id,
          vesselName: v.name,
          expressions: this.getExpressions(v.id, v.name),
          comment: this.getComment(v.id),
          expressionType: this.getExpressionType(v.id),
        };
      });
    }
  }
}
