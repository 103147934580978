import _ from "underscore";
import SelectOption from "ViewModels/selectOption";

export default {
  getConditionTypeSelectOptions: function (vesselFeatures, vessel) {
    var options = [
      new SelectOption("VesselDataOverdue", "vessel data is overdue"),
    ];
    var hasDiagnosticsFeature = _.any(vesselFeatures, function (feature) {
      return feature.name === "Diagnostics";
    });
    if (hasDiagnosticsFeature) {
      options.push(new SelectOption("PerformanceStatusObserve", "vessel performance status is Observe"));
      options.push(new SelectOption("PerformanceStatusNotOk", "vessel performance status is Not Ok"));
    }

    var hasKspSystem = vessel.onboardSystem.split(", ").includes("Ksp");
    if (hasKspSystem) {
      options.push(new SelectOption("ManualInputValueStale", "manual input has not been updated"));
    }

    options.push(new SelectOption("LogDataExpression", "log data matches custom expression"));

    return options;
  },
  getTargetKeySelectionOptions: function (conditionType) {
    switch (conditionType) {
      case "ManualInputValueStale":
        return [
          new SelectOption("meta:fuel-property", "fuel properties"),
          new SelectOption("property:draft", "draft"),
        ];

      default:
        return [];
    }
  },
  getConditionTypeText: function (condition) {
    switch (condition.conditionType) {
      case "VesselDataOverdue":
        return "When vessel data is overdue";
      case "PerformanceStatusObserve":
        return "When vessel performance status is Observe";
      case "PerformanceStatusNotOk":
        return "When vessel performance status is Not ok";
      case "ManualInputValueStale": {
        switch (condition.targetKey) {
          case "meta:fuel-property":
            return "When manual fuel properties has not been updated";
          case "property:draft":
            return "When manual draft has not been updated";

          default:
            throw "Unexpected condition target key: " + condition.targetKey;
        }
      }
      case "LogDataExpression":
        return "When log data matches custom expression";
      case "LogDataMathExpression":
        return "When log data matches mathematical expression";
      case "ShapoliPowerReserveBreach":
        return "ShaPoLi breach event";
      case "VoyageComplete":
        return "Voyage completed event";
      case "VariableOffline":
        return "Variable offline event";
      case "MissingSignal":
        return "Sensor signal missing event";
      case "LogDataMathExpression":
        return "when log data matches mathematical custom expression";
      case "SpeedLossWarning":
        return `If Speed Loss (%) reaches ${condition.content}% relative to baseline`;
      case "SpeedLossCritical":
        return `If Speed Loss (%) reaches ${condition.content}% relative to baseline`;
      case "FuelConsumptionWarning":
        return `If Fuel Consumption is Above ${condition.content}`;
      case "FuelConsumptionCritical":
        return `If Fuel Consumption is Above ${condition.content}`;
      default:
        throw "Unexpected condition type: " + conditionType;
    }
  },
  getTargetKeyByConditionType: function (conditionType) {
    switch (conditionType) {
      case "VesselDataOverdue":
        return "last-imported-date";
      case "PerformanceStatusObserve":
        return "perf-status-observe";
      case "PerformanceStatusNotOk":
        return "perf-status-notok";
      case "LogDataExpression":
        return "log-data-expression";
      case "LogDataMathExpression":
        return "LogDataMathExpression";
      case "ShapoliPowerReserveBreach":
        return "ShapoliPowerReserveBreach";
      case "VoyageComplete":
        return "VoyageComplete";
      case "VariableOffline":
        return "VariableOffline";
      case "MissingSignal":
        return "MissingSignal";
      case "SpeedLossWarning":
        return "SpeedLossWarning";
      case "SpeedLossCritical":
        return "SpeedLossCritical";
      case "FuelConsumptionWarning":
        return "FuelConsumptionWarning";
      case "FuelConsumptionCritical":
        return "FuelConsumptionCritical";
      default:
        return "";
    }
  },
  getConditionTimezoneText: function (condition) {
    switch (condition.conditionType) {
      case "VesselDataOverdue":
      case "PerformanceStatusObserve":
      case "PerformanceStatusNotOk":
      case "ManualInputValueStale":
        return "UTC";
      case "LogDataExpression":
        return "";
      default:
        throw "Unexpected condition type: " + condition.conditionType;
    }
  },
};
