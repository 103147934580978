




































































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import store from "@/store";
//  helpers
import durationHelper from "Utilities/duration-helper";
import _ from "underscore";
// types
import { ExtendedVessel } from "@/types/Vessel";
import { FleetThresholds, Channel, Condition, Threshold } from "@/types/condition";
import { UserProfile } from "@/types/userProfile";
//  modules
import { getModule } from "vuex-module-decorators";
import UsersModule from "@/store/clients/Users.module";

const Users = getModule(UsersModule, store);

@Component({
  components: {},
})
export default class NotificationConfiguration extends Vue {
  @Prop({ default: () => [], required: true }) vessels!: ExtendedVessel[];
  @Prop({ default: () => [], required: true }) thresholds!: FleetThresholds;
  @Prop({ default: () => [], required: true }) emails!: Channel[];
  @Prop({ default: () => [], required: true }) users!: UserProfile[];
  @Prop() conditions!: Condition[];
  @Prop() group!: Condition[];
  @Prop({ default: false }) shapoliEvent!: boolean;
  @Prop({ default: false }) voyageEvent!: boolean;
  @Prop({ default: false }) variableOfflineEvent!: boolean;
  @Prop({ default: false }) missingSignalEvent!: boolean;

  // @Getters
  get getUsers(): UserProfile[] {
    return Users.users;
  }

  get expressionThresholds(): Threshold[] {
    return this.thresholds.LogDataExpressionThresholds.length ? this.thresholds.LogDataExpressionThresholds : this.thresholds.LogDataMathExpressionThresholds;
  }
  get isDataOverdueThresholdsFilled(): boolean {
    return Boolean(this.thresholds.VesselDataOverdueThresholds.length);
  }

  get isPerformanceStatusObserveThresholdsFilled(): boolean {
    return Boolean(this.thresholds.PerformanceStatusObserveThresholds.length);
  }

  get isPerformanceStatusNotOkThresholdsFilled(): boolean {
    return Boolean(this.thresholds.PerformanceStatusNotOkThresholds.length);
  }

  get isSpeedLossThresholdsFilled(): boolean {
    return Boolean(this.thresholds.SpeedLossWarningThresholds.length || this.thresholds.SpeedLossCriticalThresholds.length);
  }

  get isFuelConsumptionThresholdsFilled(): boolean {
    return Boolean(this.thresholds.FuelConsumptionWarningThresholds.length || this.thresholds.FuelConsumptionCriticalThresholds.length);
  }

  get isLogDataExpressionThresholdsFilled(): boolean {
    return Boolean(this.thresholds.LogDataExpressionThresholds.length);
  }

  get isLogDataMathExpressionThresholdsFilled(): boolean {
    return Boolean(this.thresholds.LogDataMathExpressionThresholds.length);
  }

  get isManualInputValueStaleThresholdsFilled(): boolean {
    return Boolean(this.thresholds.ManualInputValueStaleThresholds.length);
  }

  get manualInputValueStaleOption(): string | undefined {
    const conditions = this.group ? this.group : this.conditions;
    if (!conditions) return;
    const manualInputStaleConditions = conditions.filter(g => g.conditionType === "ManualInputValueStale");
    if (manualInputStaleConditions.length) return manualInputStaleConditions[0].targetKey === "meta:fuel-property" ? "Fuel Properties" : "Draft";
  }

  get isEmailMappingApplied(): boolean {
    const conditions = this.group ? this.group : this.conditions;
    if (!conditions) return false;
    let isApplied = false;
    if (!conditions) return isApplied;
    if (conditions.length > 1) {
      isApplied = conditions.some(cond => conditions[0].channels.sort().join(",") !== cond.channels.sort().join(","));
    } else if (conditions.length === 1 && this.emails.length === 0) {
      isApplied = this.emails.length === 0 && conditions[0].channels.length > 0;
    }
    return isApplied;
  }

  get usersFromConditions(): UserProfile[] {
    const conditions = this.group ? this.group : this.conditions;
    const flatten = _.flatten(_.pluck(conditions, "channels"));
    const uniq = _.uniq(flatten, x => x.userId);
    return this.getUsers.filter(u => uniq.find(x => x.userId === u.id));
  }

  get emailsFromConditions(): Channel[] {
    const conditions = this.group ? this.group : this.conditions;
    const flatten = _.flatten(_.pluck(conditions, "channels")).filter(c => c.userId == null);
    const uniq = _.uniq(flatten, x => x.email);
    return uniq;
  }

  //  @Methods
  openEditDialog(): void {
    this.$emit("openChannels", this.group);
  }

  severityColor(severity: string): string {
    return severity === "Critical" ? "error" : "warning";
  }

  durationValueFromSeconds(seconds: number): string {
    return durationHelper.getDurationValueFromSeconds(seconds);
  }

  getSpeedLossPercentage(severity: string): string {
    const conditions = this.group ? this.group : this.conditions;
    if (!conditions) return "";
    const speedLossConditionBySeverity = conditions.find(c => c.targetKey.includes("SpeedLoss") && c.thresholds[0].severity === severity);
    if (speedLossConditionBySeverity) return speedLossConditionBySeverity?.content ?? "";
    return "";
  }

  getFuelConsumptionValue(severity: string): string {
    const conditions = this.group ? this.group : this.conditions;
    if (!conditions) return "";
    const fuelConsumptionConditionBySeverity = conditions.find(c => c.targetKey.includes("FuelConsumption") && c.thresholds[0].severity === severity);
    if (fuelConsumptionConditionBySeverity) return fuelConsumptionConditionBySeverity?.content ?? "";
    return "";
  }

  durationUnitFromSeconds(seconds: number): string {
    return durationHelper.getDurationUnitFromSeconds(seconds);
  }
}
