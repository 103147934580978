


























































































































































import { Component, Vue, PropSync, Prop, Watch } from "vue-property-decorator";
import store from "@/store";
// types
import { CustomExpressionData } from "@/types/CustomExpressionData";
import { ExtendedVessel } from "@/types/Vessel";
import { LogVariable } from "@/types/logVariable";

// components
import LogVariablesDialog from "@/components/widgets/LogVariablesDialog.vue";

// modules
import { getModule } from "vuex-module-decorators";
import LogVariablesModule from "@/store/clients/LogVariables.module";
import VesselsModule from "@/store/clients/Vessels.module";

const LogVariables = getModule(LogVariablesModule, store);
const Vessels = getModule(VesselsModule, store);

@Component({
  components: {
    LogVariablesDialog,
  },
})
export default class MathematicalExpressionSelect extends Vue {
  @PropSync("expression", { required: true }) syncExpression!: CustomExpressionData;
  @Prop({ required: true }) vesselId!: number;
  @Prop({ default: false }) isLogicExpressionApplied!: boolean;

  // @Data
  operators = ["AND", "OR", "(", ")", "+", "-", "/", "*"];
  LogVariablesDialog = false;
  operator: string | null = null;
  symbol: string | null = null;
  amount: number = 0;
  conditionsList: { text: string; symbol: string }[] = [
    {
      text: "Greater than",
      symbol: ">",
    },
    {
      text: "Less than",
      symbol: "<",
    },
    {
      text: "Greater than OR equal to",
      symbol: ">=",
    },
    {
      text: "Less than OR equal to",
      symbol: "<=",
    },
    {
      text: "Equal to",
      symbol: "=",
    },
  ];
  logVariablesColumn: any = {
    logVariables: [] as LogVariable[],
  };

  // @Watchers
  @Watch("logVariablesColumn", { deep: true })
  onLogVariablesColumnChanged(): void {
    this.onLogVariableAdd();
    this.$emit("onMathExpressionChanged");
  }

  // @Getters
  get isVesselLogVariablesLoaded(): boolean {
    return Boolean(this.vesselLogVariables);
  }

  get vesselLogVariables(): LogVariable[] | undefined {
    return LogVariables.logVariableByVesselId(this.vesselId);
  }

  get selectedLogVariable(): LogVariable {
    return this.logVariablesColumn.logVariables[0] ?? ({} as LogVariable);
  }

  get selectedLogVariableName(): string {
    return this.selectedLogVariable?.displayName ?? this.selectedLogVariable?.name;
  }

  get selectedLogVariableID(): number {
    return this.selectedLogVariable.id;
  }

  get selectedLogVariableUnitName(): string {
    return this.selectedLogVariable?.unit?.name;
  }

  get vessel(): ExtendedVessel | undefined {
    return Vessels.getExtendedVesselById(this.vesselId);
  }

  // @Methods
  onOperatorAdd(): void {
    if (this.operator != null) {
      this.syncExpression.content += ` ${this.operator}`;
      this.syncExpression.mathContent += ` ${this.operator}`;
    }
    (this.$refs.MathematicalExpressionSelect as any).reset();
    (this.$refs.expressionAmount as any).internalValue = 0;
  }

  onLogVariableAdd(): void {
    this.syncExpression.content += ` {${this.selectedLogVariableID}}`;
    this.syncExpression.mathContent += ` ${this.selectedLogVariableName}`;
    (this.$refs.expressionAmount as any).internalValue = 0;
  }

  onSymbolAdd(): void {
    if (this.symbol != null) {
      this.syncExpression.content += ` ${this.symbol}`;
      this.syncExpression.mathContent += ` ${this.symbol}`;
    }
    (this.$refs.MathematicalExpressionSelect as any).reset();
    (this.$refs.expressionAmount as any).internalValue = 0;
  }

  onAmountAdd(): void {
    if (this.amount != null) {
      this.syncExpression.content += ` ${this.amount}`;
      this.syncExpression.mathContent += ` ${this.amount}`;
    }
    (this.$refs.MathematicalExpressionSelect as any).reset();
    (this.$refs.expressionAmount as any).internalValue = 0;
  }

  onAmountInput($value: any): void {
    if ($value === "") {
      $value = 0;
      (this.$refs.expressionAmount as any).internalValue = $value;
    }
  }

  onLogVariableInputClick(): void {
    this.LogVariablesDialog = true;
  }

  onLogVariableInputClear(): void {
    this.logVariablesColumn.logVariables = [] as LogVariable[];
  }

  numericFilter(event: any): void {
    event = event ? event : window.event;
    const expect = event.target.value.toString() + event.key.toString();
    return !/^\d+$/.test(expect) ? event.preventDefault() : false;
  }

  onClearMathResult(): void {
    this.syncExpression.content = "";
    this.syncExpression.amount = 0;
    this.syncExpression.logVariable = null;
    this.syncExpression.operator = null;
    this.syncExpression.symbol = "";
    setTimeout(() => {
      this.syncExpression.mathContent = "";
    }, 100);
  }

  async fetchVesselLogVariables(): Promise<void> {
    if (!this.isVesselLogVariablesLoaded) await LogVariables.fetchAllLogVariablesByVesselId(this.vesselId);
  }

  async created(): Promise<void> {
    await this.fetchVesselLogVariables();
    if (this.syncExpression.content == null || this.syncExpression.mathContent == null) {
      this.syncExpression.content = "";
      this.syncExpression.mathContent = "";
    }
  }
}
