




































































import { Component, Vue, PropSync, Watch } from "vue-property-decorator";
import store from "@/store";
import conditionTypeHelper from "Utilities/condition-type-helper";
// types
import { Channel, Condition } from "@/types/condition";
import { UserProfile } from "@/types/userProfile";
// modules
import { getModule } from "vuex-module-decorators";
import VesselsModule from "@/store/clients/Vessels.module";
import UsersModule from "@/store/clients/Users.module";

const Vessels = getModule(VesselsModule, store);
const Users = getModule(UsersModule, store);

@Component({
  components: {},
})
export default class CustomRecipientItem extends Vue {
  @PropSync("condition", { required: true }) syncCondition!: Condition;
  @PropSync("recipients", { required: true }) syncRecipients!: Channel[];
  // @Watchers
  @Watch("syncCondition", { deep: true })
  onSyncConditionUpdate(): void {
    this.selectedRecipients = this.syncCondition.channels;
  }
  // @Data
  selectedRecipients: Channel[] = [];

  // @Getters
  get getConditionTypeText(): string {
    return conditionTypeHelper.getConditionTypeText(this.syncCondition);
  }

  get vesselName(): string {
    const vessel = Vessels.getExtendedVesselById(this.syncCondition.vesselId);
    return vessel ? vessel.name : "";
  }

  get users(): UserProfile[] {
    return Users.users;
  }

  get isDisplayed(): boolean {
    const vessel = Vessels.getExtendedVesselById(this.syncCondition.vesselId);
    if (!vessel) return false;
    if (this.syncCondition.conditionType === "PerformanceStatusObserve" || this.syncCondition.conditionType === "PerformanceStatusNotOk") {
      return vessel.features.some(f => f.name === "Diagnostics");
    } else if (this.syncCondition.conditionType === "ShapoliPowerReserveBreach") {
      return vessel.features.some(f => f.name === "Shapoli");
    } else {
      return true;
    }
  }

  // @Methods
  userName(userId: string): string {
    const user = Users.getUserById(userId);
    return user?.name ? user.name : `${user?.email} (User)`;
  }

  onRecipientsChanged(): void {
    this.$emit("update", { vesselId: this.syncCondition.vesselId, conditionType: this.syncCondition.conditionType, channels: this.selectedRecipients });
  }

  // @Hooks
  created(): void {
    this.selectedRecipients = this.syncCondition.channels;
  }
}
