










































































































































































import { Component, Vue, Prop, PropSync } from "vue-property-decorator";
import store from "@/store";
// types
import { CustomExpressionData, ICustomExpressionItem } from "@/types/CustomExpressionData";
import { ExtendedVessel } from "@/types/Vessel";
// components
import CustomExpressionsDialog from "@/components/Notifications/CustomExpressionsDialog.vue";
//  modules
import { getModule } from "vuex-module-decorators";
import VesselsModule from "@/store/clients/Vessels.module";

const Vessels = getModule(VesselsModule, store);

@Component({
  components: {
    CustomExpressionsDialog,
  },
})
export default class CustomExpressionItem extends Vue {
  @PropSync("expression", { required: true }) syncExpression!: ICustomExpressionItem;
  @Prop({ default: false }) isOperatorSelectAvailable!: boolean;

  // @Data
  CustomExpressionsDialog = false;
  CommentDialog = false;
  CustomExpressionItem!: ICustomExpressionItem;
  editedExpressionIndex: number | null = null;
  comment: string | null = null;

  // @Getters
  get isExpressionValid(): boolean {
    return this.syncExpression.expressions.every(exp => exp.logVariable != null);
  }

  get extendedVesselById(): ExtendedVessel | undefined {
    if (!this.syncExpression) return;
    return Vessels.getExtendedVesselById(this.syncExpression.vesselId);
  }

  get operatorRequired(): boolean {
    return Boolean(this.syncExpression.expressions.length);
  }

  get isCommentDisabled(): boolean {
    return this.syncExpression.expressions.length === 0;
  }

  // @Methods
  onAddExpression(): void {
    this.CustomExpressionItem = {
      vesselId: this.syncExpression.vesselId,
      vesselName: this.syncExpression.vesselName,
      comment: this.syncExpression.comment,
      expressionType: this.syncExpression.expressionType,
      expressions: [
        {
          vesselName: this.syncExpression.vesselName,
          vesselId: this.syncExpression.vesselId,
          logVariable: null,
          amount: 0,
          symbol: "",
          operator: null,
          content: null,
          expressionType: this.syncExpression.expressionType,
        },
      ],
    };
    this.editedExpressionIndex = this.syncExpression.expressions.length > 0 ? this.syncExpression.expressions.length : 0;
    this.CustomExpressionsDialog = true;
  }
  onEditExpression(item: CustomExpressionData, index: number): void {
    Vue.set(this.CustomExpressionItem, "expressions", [item]);
    this.editedExpressionIndex = index;
    this.CustomExpressionsDialog = true;
  }

  deleteCondition(index: number | undefined): void {
    if (index == null) {
      // deleted LogDataMathExpression and set it to "Logical" type
      this.syncExpression.expressions.splice(0, 1);
      this.syncExpression.expressionType = "Logical";
    } else this.syncExpression.expressions.splice(index, 1);
    this.$emit("onConditionDeleted");
  }

  onTypeChanged(type: string): void {
    Vue.set(this.CustomExpressionItem, "expressionType", type);
    this.CustomExpressionItem.expressions.forEach(e => Vue.set(e, "expressionType", type));
    this.$emit("onTypeChanged", { vesselId: this.syncExpression.vesselId, type: type });
  }

  onSaveCustomExpressions(expressions: CustomExpressionData[]): void {
    if (this.editedExpressionIndex != null) {
      this.$emit("expressionChanged", { index: this.editedExpressionIndex, expression: expressions[0] });
    }
    this.editedExpressionIndex = null;
  }

  openCommentDialog(): void {
    this.CommentDialog = true;
  }

  closeCommentDialog(): void {
    this.CommentDialog = false;
    this.comment = this.syncExpression.comment;
  }

  saveComment(): void {
    Vue.set(this.syncExpression, "comment", this.comment);
    this.CommentDialog = false;
    this.$emit("onComment", { vesselId: this.syncExpression.vesselId, comment: this.syncExpression.comment });
  }

  logVariableName(expressionItem: CustomExpressionData): string | undefined {
    return expressionItem?.logVariable?.displayName ?? expressionItem?.logVariable?.name;
  }

  logVariableUnitName(expressionItem: CustomExpressionData): string {
    if (!expressionItem.logVariable?.unit.name) return "";
    return expressionItem.logVariable?.unit.name;
  }

  created(): void {
    this.CustomExpressionItem = {
      vesselId: this.syncExpression.vesselId,
      vesselName: this.syncExpression.vesselName,
      expressions: this.syncExpression.expressions ? this.syncExpression.expressions : [],
      expressionType: this.syncExpression.expressionType,
      comment: null,
    };
    this.comment = this.syncExpression.comment;
  }
}
