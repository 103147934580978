




























































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, PropSync, Prop, Ref, Watch } from "vue-property-decorator";
import store from "@/store";
import durationHelper from "Utilities/duration-helper";
import htmlEncoder from "Utilities/htmlencode-helper";
import conditionTypeHelper from "Utilities/condition-type-helper";
import _ from "underscore";
// types
import { ExtendedVessel } from "@/types/Vessel";
import { Channel, Condition, Threshold, FleetThresholds } from "@/types/condition";
import { NotificationConditions } from "@/types/NotificationConditions";
import { ICustomExpressionItem, CustomExpressionData } from "@/types/CustomExpressionData";
import { UserProfile } from "@/types/userProfile";

// components
import VesselSelect from "@/components/VesselSelect.vue";
import SeveritySelector from "@/components/SeveritySelector.vue";
import SeverityTextFieldSelector from "@/components/SeverityTextFieldSelector.vue";
import CustomExpressionsModule from "@/components/Notifications/CustomExpressionsModule.vue";
import CustomExpressionItem from "@/components/Notifications/CustomExpressionItem.vue";
import CustomExpressionsDialog from "@/components/Notifications/CustomExpressionsDialog.vue";
import CustomRecipientItem from "@/components/Notifications/CustomRecipientItem.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import NotificationConfiguration from "@/components/Notifications/NotificationConfiguration.vue";

//  modules
import { getModule } from "vuex-module-decorators";
import VesselsModule from "@/store/clients/Vessels.module";
import UsersModule from "@/store/clients/Users.module";
import UserModule from "@/store/clients/User.module";
import ChannelsModule from "@/store/clients/Channels.module";
import SnackbarModule from "@/store/clients/Snackbar.module";
import IncidentsModule from "@/store/clients/Incidents.module";
import LogVariablesModule from "@/store/clients/LogVariables.module";

const Vessels = getModule(VesselsModule, store);
const Snackbar = getModule(SnackbarModule, store);
const Users = getModule(UsersModule, store);
const User = getModule(UserModule, store);
const Channels = getModule(ChannelsModule, store);
const Incidents = getModule(IncidentsModule, store);
const LogVariables = getModule(LogVariablesModule, store);

@Component({
  components: {
    VesselSelect,
    SeveritySelector,
    SeverityTextFieldSelector,
    CustomExpressionsModule,
    CustomExpressionItem,
    CustomExpressionsDialog,
    CustomRecipientItem,
    ConfirmDialog,
    NotificationConfiguration,
  },
})
export default class ConditionStepper extends Vue {
  @PropSync("notification", { required: true }) syncNotification!: NotificationConditions;
  @Prop({ default: false }) isEditMode!: boolean;
  @Prop() commonSelectedUsers!: UserProfile[];
  @Prop() commonSelectedEmails!: Channel[];
  @Prop({ default: 1 }) conditionStep!: number;
  @Ref("confirmDelete") confirmDelete!: any;
  @Ref("CustomExpressionsModule") CustomExpressionsModule!: any;

  @Watch("thresholds", { deep: true })
  onThresholdsChanged(): void {
    Vue.set(this.thresholds, "LogDataMathExpressionThresholds", this.thresholds.LogDataExpressionThresholds);
    this.updateConditions();
  }

  @Watch("speedLossPercentage", { deep: true })
  onSpeedLossPercentageChanged(): void {
    this.updateConditions();
  }

  @Watch("fuelConsumptionValue", { deep: true })
  onFuelConsumptionValueChanged(): void {
    this.updateConditions();
  }

  @Watch("notifyShaPoLiEvent")
  onNotifyShaPoLiChanged(): void {
    if (this.notifyShaPoLiEvent) {
      this.thresholds.ShapoliPowerReserveBreachThresholds = [
        {
          durationInSeconds: 60,
          severity: "Critical",
        } as Threshold,
      ];
    } else {
      this.thresholds.ShapoliPowerReserveBreachThresholds = [];
    }
    this.updateConditions();
  }

  @Watch("notifyVoyageEvent")
  onNotifyVoyageChanged(): void {
    if (this.notifyVoyageEvent) {
      this.thresholds.VoyageCompleteThresholds = [
        {
          durationInSeconds: 60,
          severity: "Warning",
        } as Threshold,
      ];
    } else {
      this.thresholds.VoyageCompleteThresholds = [];
    }
    this.updateConditions();
  }

  @Watch("notifyVariableOfflineEvent")
  onNotifyVariableOfflineChanged(): void {
    if (this.notifyVariableOfflineEvent) {
      this.thresholds.VariableOfflineThresholds = [
        {
          durationInSeconds: 60,
          severity: "Critical",
        } as Threshold,
      ];
    } else {
      this.thresholds.VariableOfflineThresholds = [];
    }
    this.updateConditions();
  }

  @Watch("notifyMissingSignalEvent")
  onNotifyMissingSignalEventChanged(): void {
    if (this.notifyMissingSignalEvent) {
      this.thresholds.MissingSignalThresholds = [
        {
          durationInSeconds: 60,
          severity: "Critical",
        } as Threshold,
      ];
    } else {
      this.thresholds.MissingSignalThresholds = [];
    }
    this.updateConditions();
  }

  // @Data
  step = 1;
  CustomExpressionsDialog = false;
  vesselLogVariablesModal = false;
  showCreatedExpressionsDataList = false;
  selectedVessel: any = null;
  notifyShaPoLiEvent = false;
  notifyVoyageEvent = false;
  notifyVariableOfflineEvent = false;
  notifyMissingSignalEvent = false;
  expressionsDataList: CustomExpressionData[] = [];
  customExpressionItemsList: ICustomExpressionItem[] = [];
  selectedChannels: Channel[] = [];
  selectedUsers: UserProfile[] = [];
  selectedVesselsNameString = "";
  thresholds: FleetThresholds = {
    VesselDataOverdueThresholds: [],
    PerformanceStatusObserveThresholds: [],
    PerformanceStatusNotOkThresholds: [],
    SpeedLossWarningThresholds: [],
    SpeedLossCriticalThresholds: [],
    FuelConsumptionWarningThresholds: [],
    FuelConsumptionCriticalThresholds: [],
    LogDataExpressionThresholds: [],
    LogDataMathExpressionThresholds: [],
    ManualInputValueStaleThresholds: [],
    ShapoliPowerReserveBreachThresholds: [],
    VoyageCompleteThresholds: [],
    VariableOfflineThresholds: [],
    MissingSignalThresholds: [],
  };
  manualInputVariables: { text: string; type: string }[] = [
    {
      text: "Fuel properties",
      type: "meta:fuel-property",
    },
    {
      text: "Draft",
      type: "property:draft",
    },
  ];
  selectedManualInputVariable: { text: string; type: string } = this.manualInputVariables[0];
  newCustomEmail: string | null = null;
  emailRules = {
    valid: (value: string) => (/^([\w\.\-\+]+)@([\w\-]+)((\.(\w){2,6})+)$/.test(value) && value.length) || value == null || "Email must be valid",
  };
  speedLossPercentage: any = {
    warning: 0,
    critical: 0,
  };
  fuelConsumptionValue: any = {
    warning: 0,
    critical: 0,
  };

  //  @Getters
  get extendedVessels(): ExtendedVessel[] {
    return Vessels.extendedVessels;
  }

  get users(): UserProfile[] {
    return Users.users;
  }

  get syncNotificationVessels(): ExtendedVessel[] {
    return this.syncNotification.vessels ?? [];
  }

  get emailChannels(): Channel[] {
    return Channels.emailChannels;
  }

  get recipientsList(): Channel[] {
    return [...this.emailChannels, ...this.users.map(u => ({ userId: u.id, email: u.email }))];
  }

  get selectedRecipientsList(): Channel[] {
    return [...this.selectedChannels, ...this.selectedUsers.map(u => ({ userId: u.id, email: u.email }))];
  }

  get isFirstStepComplete(): boolean {
    if (!this.syncNotification.vessels) return false;
    return this.syncNotification.vessels.length > 0;
  }

  get isVesselDataOverdueThresholdsFilled(): boolean {
    return Boolean(this.thresholds.VesselDataOverdueThresholds.length);
  }

  get isPerformanceStatusObserveThresholdsFilled(): boolean {
    return Boolean(this.thresholds.PerformanceStatusObserveThresholds.length);
  }

  get isSpeedLossThresholdsFilled(): boolean {
    return Boolean(this.thresholds.SpeedLossWarningThresholds.length || this.thresholds.SpeedLossWarningThresholds.length);
  }

  get isFuelConsumptionThresholdsFilled(): boolean {
    return Boolean(this.thresholds.FuelConsumptionWarningThresholds.length || this.thresholds.FuelConsumptionCriticalThresholds.length);
  }

  get isPerformanceStatusNotOkThresholdsFilled(): boolean {
    return Boolean(this.thresholds.PerformanceStatusNotOkThresholds.length);
  }

  get isLogDataExpressionThresholdsFilled(): boolean {
    return Boolean(this.thresholds.LogDataExpressionThresholds.length);
  }

  get isLogDataMathExpressionThresholdsFilled(): boolean {
    return Boolean(this.thresholds.LogDataMathExpressionThresholds.length);
  }

  get isManualInputValueStaleThresholdsFilled(): boolean {
    return Boolean(this.thresholds.ManualInputValueStaleThresholds.length);
  }

  get logDataExpressionTypeConditions(): Condition[] {
    return this.syncNotification.conditions.filter(cond => cond.conditionType === "LogDataExpression" || cond.conditionType === "LogDataMathExpression");
  }

  get hasLogDataExpressions(): boolean {
    if (!this.logDataExpressionTypeConditions.length) return false;
    return this.logDataExpressionTypeConditions.every(item => item.content);
  }

  get manualInputStaleTypeConditions(): Condition[] {
    return this.syncNotification.conditions.filter(cond => cond.conditionType === "ManualInputValueStale");
  }

  get vesselsWithoutShaPoLiModule(): string {
    const vesselsWithoutShaPoLiModule: ExtendedVessel[] = [];
    this.syncNotification.vessels?.forEach(v => {
      if (v.features.every(f => f.name !== "Shapoli")) vesselsWithoutShaPoLiModule.push(v);
    });
    return vesselsWithoutShaPoLiModule.map(vessel => vessel.name).join(", ");
  }
  get vesselsWithoutLogVariableOfflineModule(): string {
    if (!this.syncNotification.vessels) return "";
    return this.syncNotification.vessels
      .filter(vessel => vessel.onboardSystem !== "Ksp")
      .map(v => v.name)
      .join(", ");
  }

  get isVesselLogVaribleOfflineDisabled(): boolean {
    if (!this.syncNotification.vessels) return false;
    if (this.syncNotification.vessels.some(v => v.onboardSystem === "Ksp")) return false;
    return true;
  }

  get vesselsWithoutDiagnosticsModule(): string {
    const vesselsWithoutDiagnosticsModule: ExtendedVessel[] = [];
    this.syncNotification.vessels?.forEach(v => {
      if (v.features.every(f => f.name !== "Diagnostics")) vesselsWithoutDiagnosticsModule.push(v);
    });
    return vesselsWithoutDiagnosticsModule.map(vessel => vessel.name).join(", ");
  }

  get vesselsWithoutFoulingModule(): string {
    const vesselsWithoutFoulingModule: ExtendedVessel[] = [];
    this.syncNotification.vessels?.forEach(v => {
      if (v.features.every(f => f.name !== "Fouling")) vesselsWithoutFoulingModule.push(v);
    });
    return vesselsWithoutFoulingModule.map(vessel => vessel.name).join(", ");
  }

  get isShapoliModuleDisabledForAllVessels(): boolean {
    if (!this.syncNotification.vessels) return false;
    return this.syncNotification.vessels.every(v => v.features.every(f => f.name !== "Shapoli"));
  }

  get isDiagnosticsModuleDisabledForAllVessels(): boolean {
    if (!this.syncNotification.vessels) return false;
    return this.syncNotification.vessels.every(v => v.features.every(f => f.name !== "Diagnostics"));
  }

  get isFoulingModuleDisabledForAllVessels(): boolean {
    if (!this.syncNotification.vessels) return false;
    return this.syncNotification.vessels.every(v => v.features.every(f => f.name !== "Fouling"));
  }

  get isSpeedLossCriticalLessThanWarning(): boolean {
    if (this.speedLossPercentage.critical === 0 || this.speedLossPercentage.warning === 0) return false;
    return this.speedLossPercentage.critical <= this.speedLossPercentage.warning;
  }

  get isFuelConsumptionCriticalLessThanWarning(): boolean {
    if (this.fuelConsumptionValue.critical === 0 || this.fuelConsumptionValue.warning === 0) return false;
    return this.fuelConsumptionValue.critical <= this.fuelConsumptionValue.warning;
  }

  get hasEventWithDisabledModule(): boolean {
    return this.syncNotification.conditions.some(c => {
      if (
        c.conditionType === "PerformanceStatusObserve" ||
        c.conditionType === "PerformanceStatusNotOk" ||
        c.conditionType === "ShapoliPowerReserveBreach" ||
        c.conditionType.includes("SpeedLoss") ||
        c.conditionType.includes("FuelConsumption")
      ) {
        return this.hasDisabledModule(c.vesselId, c.conditionType);
      } else return false;
    });
  }

  get isClearExpressionOperatorsDisabled(): boolean {
    return this.expressionsDataList.every(e => e.operator === null);
  }

  get isEmailValid(): boolean {
    if (typeof this.emailRules.valid(this.newCustomEmail ?? "") === "string") return true;
    return false;
  }

  get isAnyThresholdsFilled(): boolean {
    return (
      Object.keys(this.thresholds).some(
        key =>
          key !== "ShapoliPowerReserveBreachThresholds" &&
          key !== "VoyageCompleteThresholds" &&
          key !== "VariableOfflineThresholds" &&
          key !== "MissingSignalThresholds" &&
          (this.thresholds as any)[key].length > 0
      ) ||
      this.notifyShaPoLiEvent ||
      this.notifyVoyageEvent ||
      this.notifyVariableOfflineEvent ||
      this.notifyMissingSignalEvent
    );
  }

  get isAddCustomMailAvailable(): boolean {
    return User.userRole === "SuperUser" || User.userRole === "Administrator";
  }

  async conditions(): Promise<Condition[]> {
    let conditions: Condition[] = this.syncNotification.conditions.length ? this.syncNotification.conditions : [];
    //  if it's only one condition, then delete it and post conditions as a new fleet group
    if (conditions.length === 1) {
      conditions.forEach(cond => (cond.isDeleted = true));
      await Incidents.updateCondition(conditions);
    }
    if (this.isVesselDataOverdueThresholdsFilled) conditions = [...conditions, ...this.conditionsConstructor("VesselDataOverdue", "last-imported-date")];
    if (this.isPerformanceStatusObserveThresholdsFilled) conditions = [...conditions, ...this.conditionsConstructor("PerformanceStatusObserve", "perf-status-observe")];
    if (this.isPerformanceStatusNotOkThresholdsFilled) conditions = [...conditions, ...this.conditionsConstructor("PerformanceStatusNotOk", "perf-status-notok")];
    if (this.isLogDataExpressionThresholdsFilled) conditions = [...conditions, ...this.conditionsConstructor("LogDataExpression", "log-data-expression")];
    if (this.isLogDataMathExpressionThresholdsFilled) conditions = [...conditions, ...this.conditionsConstructor("LogDataMathExpression", "LogDataMathExpression")];
    if (this.notifyShaPoLiEvent) conditions = [...conditions, ...this.conditionsConstructor("ShapoliPowerReserveBreach", "ShapoliPowerReserveBreach")];
    if (this.notifyMissingSignalEvent) conditions = [...conditions, ...this.conditionsConstructor("MissingSignal", "MissingSignal")];
    if (this.isManualInputValueStaleThresholdsFilled) conditions = [...conditions, ...this.conditionsConstructor("ManualInputValueStale", this.selectedManualInputVariable.type)];
    if (this.notifyVoyageEvent) conditions = [...conditions, ...this.conditionsConstructor("VoyageComplete", "VoyageComplete")];
    if (this.notifyVariableOfflineEvent) conditions = [...conditions, ...this.conditionsConstructor("VariableOffline", "VariableOffline")];

    return conditions;
  }

  // @Methods
  conditionsConstructor(type: string, targetKey: string): Condition[] {
    if (!this.syncNotification.vessels) return [] as Condition[];
    return this.syncNotification.vessels.map(v => {
      return {
        id: this.notificationConditionId(v.id, type),
        vesselId: v.id,
        conditionType: type,
        conditionDetails: this.expressionConditionDetails(v.id, type),
        targetKey: targetKey ? targetKey : "",
        content: this.expressionContentByVesselId(v.id, type),
        contentDescription: this.contentDescription(v.id),
        channels: this.conditionChannels(v.id, type).length ? this.conditionChannels(v.id, type) : JSON.parse(JSON.stringify(this.selectedRecipientsList)),
        thresholds: this.thresholdsByType(type),
      } as Condition;
    });
  }

  thresholdsByType(type: string): Threshold[] {
    switch (type) {
      case "VesselDataOverdue":
        return this.thresholds.VesselDataOverdueThresholds;
      case "PerformanceStatusObserve":
        return this.thresholds.PerformanceStatusObserveThresholds;
      case "PerformanceStatusNotOk":
        return this.thresholds.PerformanceStatusNotOkThresholds;
      case "SpeedLossWarning":
        return this.thresholds.SpeedLossWarningThresholds;
      case "SpeedLossCritical":
        return this.thresholds.SpeedLossCriticalThresholds;
      case "FuelConsumptionWarning":
        return this.thresholds.FuelConsumptionWarningThresholds;
      case "FuelConsumptionCritical":
        return this.thresholds.FuelConsumptionCriticalThresholds;
      case "LogDataExpression":
        return this.thresholds.LogDataExpressionThresholds;
      case "LogDataMathExpression":
        return this.thresholds.LogDataExpressionThresholds;
      case "ShapoliPowerReserveBreach":
        return this.thresholds.ShapoliPowerReserveBreachThresholds;
      case "MissingSignal":
        return this.thresholds.MissingSignalThresholds;
      case "ManualInputValueStale":
        return this.thresholds.ManualInputValueStaleThresholds;
      case "VoyageComplete":
        return this.thresholds.VoyageCompleteThresholds;
      case "VariableOffline":
        return this.thresholds.VariableOfflineThresholds;
      default:
        return [];
    }
  }

  numericFilter(event: any): void {
    event = event ? event : window.event;
    const expect = event.target.value.toString() + event.key.toString();
    return !/^\d+$/.test(expect) ? event.preventDefault() : false;
  }

  notificationConditionId(vesselId: number, conditionType: string): number {
    const existingCondition = this.syncNotification.conditions.find(c => c.vesselId === vesselId && c.conditionType === conditionType);
    if (existingCondition && existingCondition.id && existingCondition.id !== 0) return existingCondition.id;
    else return 0;
  }

  hasDisabledModule(vesselId: number, conditionType: string): boolean {
    if (!this.syncNotification.vessels) return false;
    let hasDisabledModule = false;
    const vessel = this.syncNotification.vessels.find(v => v.id === vesselId);
    if (!vessel) return false;
    if ((conditionType === "PerformanceStatusObserve" || conditionType === "PerformanceStatusNotOk") && vessel.features.filter(f => f.name === "Diagnostics").length === 0) hasDisabledModule = true;
    else if (conditionType === "ShapoliPowerReserveBreach" && vessel.features.filter(f => f.name === "Shapoli").length === 0) hasDisabledModule = true;
    else if ((conditionType.includes("SpeedLoss") || conditionType.includes("FuelConsumption")) && vessel.features.filter(f => f.name === "Fouling").length === 0) hasDisabledModule = true;
    return hasDisabledModule;
  }

  conditionChannels(vesselId: number, conditionType: string): Channel[] {
    const existingCondition = this.syncNotification.conditions.find(c => c.vesselId === vesselId && c.conditionType === conditionType);
    if (existingCondition && existingCondition.channels.length) return existingCondition.channels;
    else return [] as Channel[];
  }

  expressionClausesByVesselId(vesselId: number): CustomExpressionData[] {
    const vesselExpressionItem = this.customExpressionItemsList.find(item => item.vesselId === vesselId) ?? ({} as ICustomExpressionItem);
    return JSON.parse(JSON.stringify(vesselExpressionItem?.expressions ?? ([] as CustomExpressionData[])));
  }

  createExpressionTree(expressionClauses: CustomExpressionData[]): object {
    let expressionTree: any = null;

    expressionClauses.forEach(clause => {
      const newExpressionTree = this.BinaryExpression(clause);

      if (!expressionTree) {
        expressionTree = newExpressionTree;
      } else if (clause.operator === "AND") {
        expressionTree = {
          type: "LogicalExpression",
          token: "AND",
          left: expressionTree,
          right: newExpressionTree,
        };
      } else {
        if (expressionTree.type === "BinaryExpression") {
          expressionTree = {
            type: "LogicalExpression",
            token: "OR",
            left: expressionTree,
            right: newExpressionTree,
          };
        } else {
          expressionTree.right = {
            type: "LogicalExpression",
            token: "OR",
            left: expressionTree.right,
            right: newExpressionTree,
          };
        }
      }
    });

    return expressionTree;
  }

  expressionConditionDetails(vesselId: number, type: string): string | null {
    if (type === "LogDataExpression" || type === "LogDataMathExpression") {
      const existingCondition = this.syncNotification.conditions.find(c => c.vesselId === vesselId && c.conditionType === type);
      if (existingCondition && existingCondition.conditionDetails && existingCondition.conditionDetails !== null) return existingCondition.conditionDetails;
      return null;
    }
    return null;
  }

  expressionContentByVesselId(vesselId: number, type: string): string | null {
    if ((!this.customExpressionItemsList.length && type.includes("Expression")) || (this.isEditMode && !type.includes("SpeedLoss") && !type.includes("FuelConsumption"))) {
      const existingCondition = this.syncNotification.conditions.find(c => c.vesselId === vesselId && c.conditionType === type);
      if (existingCondition && existingCondition.content && existingCondition.conditionType === type && existingCondition.content !== null) return existingCondition.content;
      else return null;
    }
    if (type === "LogDataExpression") {
      const expressionClauses = this.expressionClausesByVesselId(vesselId);
      const expressionTree = this.createExpressionTree(expressionClauses);

      return JSON.stringify(expressionTree);
    } else if (type.includes("SpeedLoss")) {
      const severity: any = type.split("SpeedLoss")[1].toLowerCase();
      return this.speedLossPercentage[severity].toString();
    } else if (type.includes("FuelConsumption")) {
      const severity: any = type.split("FuelConsumption")[1].toLowerCase();
      return this.fuelConsumptionValue[severity].toString();
    } else return null;
  }

  BinaryExpression(clause: CustomExpressionData): any {
    return {
      type: "BinaryExpression",
      token: clause.symbol,
      left: {
        type: "Identifier",
        token: clause.logVariable?.id,
        left: null,
        right: null,
      },
      right: {
        type: "Literal",
        token: +clause.amount,
        left: null,
        right: null,
      },
    };
  }

  contentDescription(vesselId: number): string | null {
    const existingCondition = this.syncNotification.conditions.find(c => c.vesselId === vesselId && c.conditionType === "LogDataExpression");
    if (existingCondition && existingCondition.contentDescription && existingCondition.contentDescription !== null) return existingCondition.contentDescription;
    else return null;
  }

  async onStepSelectChannels(): Promise<void> {
    if (!this.isEditMode && this.syncNotification.conditions.length === 0) Vue.set(this.syncNotification, "conditions", await this.conditions());
    this.step = 3;
  }

  onConfirm(): void {
    this.step = 4;
  }

  onCreate(): void {
    if (this.isEditMode) {
      this.$emit("onUpdate", this.syncNotification);
    } else {
      this.$emit("onCreate", this.syncNotification);
      this.$router.push("/MyNotifications");
    }
  }

  onCancel(): void {
    this.syncNotification = {
      vessels: [] as ExtendedVessel[],
      conditions: [] as Condition[],
    } as NotificationConditions;
    this.$router.push("/MyNotifications");
  }

  onExpressionItemChanged(expressionItemsList: ICustomExpressionItem[]): void {
    if (!this.isEditMode) {
      expressionItemsList.forEach(expressionItem => {
        const existingConditionIndex = this.syncNotification.conditions.findIndex(
          c => c.vesselId === expressionItem.vesselId && (c.conditionType === "LogDataExpression" || c.conditionType === "LogDataMathExpression")
        );
        if (existingConditionIndex === -1) {
          this.syncNotification.conditions.push({
            id: 0,
            vesselId: expressionItem.vesselId,
            conditionType: expressionItem.expressionType === "Logical" ? "LogDataExpression" : "LogDataMathExpression",
            conditionDetails: expressionItem.expressionType === "Logical" ? this.getConditionDetailsFromArray(expressionItem.expressions) : expressionItem.expressions[0].mathContent,
            targetKey: expressionItem.expressionType === "Logical" ? "log-data-expression" : "LogDataMathExpression",
            content: expressionItem.expressions.length ? expressionItem.expressions[0].content : null,
            contentDescription: expressionItem.comment,
            channels: [],
            thresholds: [],
          } as Condition);
        } else if (existingConditionIndex !== -1 && expressionItem.vesselId === this.syncNotification.conditions[existingConditionIndex].vesselId) {
          this.syncNotification.conditions.splice(existingConditionIndex, 1, {
            id: this.syncNotification.conditions[existingConditionIndex].id,
            vesselId: expressionItem.vesselId,
            conditionType: expressionItem.expressionType === "Logical" ? "LogDataExpression" : "LogDataMathExpression",
            conditionDetails: expressionItem.expressionType === "Logical" ? this.getConditionDetailsFromArray(expressionItem.expressions) : expressionItem.expressions[0].mathContent,
            targetKey: expressionItem.expressionType === "Logical" ? "log-data-expression" : "LogDataMathExpression",
            content: expressionItem.expressions.length ? expressionItem.expressions[0].content : null,
            contentDescription: expressionItem.comment,
            channels: this.syncNotification.conditions[existingConditionIndex].channels,
            thresholds: this.syncNotification.conditions[existingConditionIndex].thresholds,
          } as Condition);
        }
      });
    } else {
      expressionItemsList.forEach(expressionItem => {
        const existingConditionIndex = this.syncNotification.conditions.findIndex(
          c => c.vesselId === expressionItem.vesselId && (c.conditionType === "LogDataExpression" || c.conditionType === "LogDataMathExpression")
        );
        if (existingConditionIndex !== -1 && expressionItem.vesselId === this.syncNotification.conditions[existingConditionIndex].vesselId) {
          this.syncNotification.conditions.splice(existingConditionIndex, 1, {
            id: this.syncNotification.conditions[existingConditionIndex].id,
            vesselId: expressionItem.vesselId,
            conditionType: expressionItem.expressionType === "Logical" ? "LogDataExpression" : "LogDataMathExpression",
            conditionDetails: expressionItem.expressionType === "Logical" ? this.getConditionDetailsFromArray(expressionItem.expressions) : expressionItem.expressions[0].mathContent,
            targetKey: expressionItem.expressionType === "Logical" ? "log-data-expression" : "LogDataMathExpression",
            content: expressionItem.expressions.length ? expressionItem.expressions[0].content : null,
            contentDescription: expressionItem.comment,
            channels: this.syncNotification.conditions[existingConditionIndex].channels,
            thresholds: this.syncNotification.conditions[existingConditionIndex].thresholds,
          } as Condition);
        } else if (existingConditionIndex === -1) {
          this.syncNotification.conditions.push({
            id: 0,
            vesselId: expressionItem.vesselId,
            conditionType: expressionItem.expressionType === "Logical" ? "LogDataExpression" : "LogDataMathExpression",
            conditionDetails: expressionItem.expressionType === "Logical" ? this.getConditionDetailsFromArray(expressionItem.expressions) : expressionItem.expressions[0].mathContent,
            targetKey: expressionItem.expressionType === "Logical" ? "log-data-expression" : "LogDataMathExpression",
            content: expressionItem.expressions.length ? expressionItem.expressions[0].content : null,
            contentDescription: expressionItem.comment,
            channels: [],
            thresholds: [],
          } as Condition);
        }
      });
      this.customExpressionItemsList = expressionItemsList;
      this.updateConditions();
    }
  }

  onExpressionTypeChanged(type: string): void {
    // Vue.set(this.CustomExpressionItem, "expressionType", type);
  }

  getConditionDetailsFromArray(expressionsArray: CustomExpressionData[]): string {
    let conditionDetails = "";
    expressionsArray.forEach(expression => {
      const str = `${expression.operator ? expression.operator : ""} ${expression.logVariable?.displayName} ${expression.symbol} ${expression.amount} ${
        expression.logVariable ? expression.logVariable.unit.name : ""
      }`;
      conditionDetails += str;
    });
    return conditionDetails;
  }

  onExpressionComment(expressionItemComment: { vesselId: number; comment: string | null }): void {
    const conditionIndex = this.syncNotification.conditions.findIndex(cond => cond.vesselId === expressionItemComment.vesselId && cond.conditionType === "LogDataExpression");
    if (conditionIndex !== -1) {
      Vue.set(this.syncNotification.conditions[conditionIndex], "contentDescription", expressionItemComment.comment);
    }
  }

  onManualInputVariableChanged(): void {
    this.syncNotification.conditions.forEach(cond => {
      if (cond.conditionType === "ManualInputValueStale") Vue.set(cond, "targetKey", this.selectedManualInputVariable.type);
    });
  }

  onSelectAllChannelsChanged($event: any): void {
    if ($event) {
      this.selectedChannels = this.emailChannels;
      this.selectedChannels.forEach(channel => {
        this.syncNotification.conditions.forEach((cond, i) => {
          if (!cond.channels.some(chnl => chnl.email === channel.email)) {
            cond.channels.push({ userId: channel.userId, email: channel.email });
          }
        });
      });
    } else {
      this.selectedChannels = [];

      this.syncNotification.conditions.forEach((cond, i) => {
        Vue.set(this.syncNotification.conditions[i], "channels", this.selectedRecipientsList);
      });
    }
  }

  onEmailChannelsChanged(channel: Channel): void {
    const isChecked = this.selectedChannels.some(chnl => chnl.email === channel.email);
    if (isChecked) {
      this.syncNotification.conditions.forEach((cond, i) => {
        if (!cond.channels.some(chnl => chnl.email === channel.email)) {
          cond.channels.push({ userId: channel.userId, email: channel.email });
        }
      });
    } else {
      this.syncNotification.conditions.forEach((cond, i) => {
        const emailChannelIndex = cond.channels.findIndex(chnl => chnl.email === channel.email);
        if (emailChannelIndex !== -1) cond.channels.splice(emailChannelIndex, 1);
      });
    }
  }

  onSelectAllUsersChanged($event: any): void {
    if ($event) {
      this.selectedUsers = this.users;
      this.selectedUsers.forEach(user => {
        this.syncNotification.conditions.forEach((cond, i) => {
          if (!cond.channels.some(chnl => chnl.userId === user.id)) {
            cond.channels.push({ userId: user.id, email: user.email });
          }
        });
      });
    } else {
      this.selectedUsers = [];

      this.syncNotification.conditions.forEach((cond, i) => {
        Vue.set(this.syncNotification.conditions[i], "channels", this.selectedRecipientsList);
      });
    }
  }

  onUsersChanged(user: UserProfile): void {
    const isChecked = this.selectedUsers.some(usr => usr.id === user.id);
    if (isChecked) {
      this.syncNotification.conditions.forEach((cond, i) => {
        if (!cond.channels.some(chnl => chnl.userId === user.id)) {
          cond.channels.push({ userId: user.id, email: user.email });
        }
      });
    } else {
      this.syncNotification.conditions.forEach((cond, i) => {
        const userIndex = cond.channels.findIndex(chnl => chnl.userId === user.id);
        if (userIndex !== -1) cond.channels.splice(userIndex, 1);
      });
    }
  }

  onRecipientsUpdate(data: { vesselId: number; conditionType: string; channels: Channel[] }): void {
    const conditionIndex = this.syncNotification.conditions.findIndex(cond => cond.vesselId === data.vesselId && cond.conditionType === data.conditionType);
    if (conditionIndex !== -1) Vue.set(this.syncNotification.conditions[conditionIndex], "channels", data.channels);
  }

  durationUnitFromSeconds(seconds: number): string {
    return durationHelper.getDurationUnitFromSeconds(seconds);
  }

  durationValueFromSeconds(seconds: number): string {
    return durationHelper.getDurationValueFromSeconds(seconds);
  }

  severityColor(severity: string): string {
    return severity === "Critical" ? "error" : "warning";
  }

  async deleteMail(channel: Channel): Promise<void> {
    const confirmed = await this.confirmDelete.open(
      "Delete saved email from all conditions?",
      "Are you sure you want to completely delete this saved email? It will instantly be removed from all conditions on all vessels using it and not only this one."
    );

    if (!confirmed) return;
    try {
      if (channel.id) await Channels.deleteChannel(channel.id);
      const chnlIndex = this.selectedChannels.findIndex(chnl => channel.email === chnl.email);
      if (chnlIndex !== -1) this.selectedChannels.splice(chnlIndex, 1);
      Channels.DELETE_CHANNEL(channel);
      Snackbar.showSnackbar({
        text: `Recipient email <b>'${htmlEncoder.htmlEncode(channel.email)}'</b> was successfully <b>deleted</b>`,
        color: "success",
      });
    } catch (error) {
      Snackbar.showSnackbar({ text: "Failed to delete channel" });
    }
  }

  submitEmail(): void {
    Channels.ADD_CHANNEL({ email: this.newCustomEmail, userId: null });
    this.newCustomEmail = null;
  }

  getTargetKeyByConditionType(conditionType: string): string {
    return conditionTypeHelper.getTargetKeyByConditionType(conditionType);
  }

  onVesselChanged(vessel: ExtendedVessel): void {
    if (!this.syncNotification.vessels) return;
    if (!this.isEditMode && this.syncNotification.vessels.length === 1) {
      //  if only one vessel selected with disabled feature, clear previous selection
      const foulingFeatureDisabled = !this.syncNotification.vessels[0].features.some(feature => feature.name === "Fouling");
      const diagnostiscFeatureDisabled = !this.syncNotification.vessels[0].features.some(feature => feature.name === "Diagnostics");
      const shapoliFeatureDisabled = !this.syncNotification.vessels[0].features.some(feature => feature.name === "Shapoli");
      if (foulingFeatureDisabled) {
        this.thresholds.SpeedLossWarningThresholds = [];
        this.thresholds.SpeedLossCriticalThresholds = [];
        this.thresholds.FuelConsumptionWarningThresholds = [];
        this.thresholds.FuelConsumptionCriticalThresholds = [];
      }
      if (diagnostiscFeatureDisabled) {
        this.thresholds.PerformanceStatusNotOkThresholds = [];
        this.thresholds.PerformanceStatusObserveThresholds = [];
      }
      if (shapoliFeatureDisabled) this.thresholds.ShapoliPowerReserveBreachThresholds = [];
    }
    const isVesselChecked = this.syncNotification.vessels.some(v => v.id === vessel.id);
    if (isVesselChecked) {
      const uniqueConditions = _.unique(this.syncNotification.conditions, x => x.conditionType);
      const emails = this.commonSelectedEmails ?? [];
      const users = this.commonSelectedUsers ?? [];
      let addedVesselConditions = uniqueConditions.map(c => {
        return {
          id: vessel.id === c.vesselId ? c.id : 0,
          vesselId: vessel.id,
          conditionType: c.conditionType === "LogDataMathExpression" ? "LogDataExpression" : c.conditionType,
          conditionDetails: c.conditionType === "LogDataExpression" && vessel.id === c.vesselId ? c.conditionDetails : null,
          targetKey: c.targetKey === "LogDataMathExpression" ? "log-data-expression" : c.targetKey,
          content: c.conditionType === "LogDataExpression" && vessel.id === c.vesselId ? c.content : null,
          contentDescription: this.contentDescription(c.vesselId),
          channels: [...emails, ...users.map(u => ({ userId: u.id, email: u.email }))],
          thresholds: c.thresholds,
        } as Condition;
      });
      addedVesselConditions = _.uniq(addedVesselConditions, "conditionType");
      const updatedConditions = JSON.parse(JSON.stringify([...this.syncNotification.conditions, ...addedVesselConditions]));
      Vue.set(this.syncNotification, "conditions", updatedConditions);
      this.updateConditions();
    } else {
      const conditionsWithoutUncheckedVessel = this.syncNotification.conditions.filter(c => c.vesselId !== vessel.id);
      Vue.set(this.syncNotification, "conditions", conditionsWithoutUncheckedVessel);
    }
    this.selectedVesselsNameString = this.syncNotification.vessels?.map(vessel => vessel.name).join(", ") ?? "";
  }

  updateConditions(): void {
    let conditions: Condition[] = [];
    Object.keys(this.thresholds).forEach((key: string) => {
      const conditionType = key.split("Thresholds")[0];
      if (!this.thresholds[key as keyof FleetThresholds].length) return;
      const targetKey = conditionType === "ManualInputValueStale" ? this.selectedManualInputVariable.type : this.getTargetKeyByConditionType(conditionType);
      conditions = [...conditions, ...this.conditionsConstructor(conditionType, targetKey)];
    });
    Vue.set(
      this.syncNotification,
      "conditions",
      this.isEditMode
        ? conditions.filter(con => {
            const vesselExistedConditions = this.syncNotification.conditions.filter(c => c.vesselId === con.vesselId);
            if (con.id === 0 && (con.conditionType === "LogDataExpression" || con.conditionType === "LogDataMathExpression") && con.conditionDetails != null) return true;
            else if (con.id === 0 && con.conditionType === "LogDataExpression" && vesselExistedConditions.some(c => c.vesselId === con.vesselId)) return false;
            else if (con.id === 0 && con.conditionType === "LogDataMathExpression" && vesselExistedConditions.some(c => c.vesselId === con.vesselId)) return false;
            else if (con.id === 0 && con.conditionType === "LogDataMathExpression" && conditions.some(c => c.vesselId === con.vesselId && c.conditionType === "LogDataExpression")) return false;
            return true;
          })
        : this.removeLogDataExpressionsDuplicates(conditions)
    );

    //  remove conditions that contain disabled features
    if (!(this.syncNotification.vessels && this.syncNotification.vessels.length)) return;
    const foulingFeatureDisabled = this.syncNotification.vessels.filter(vessel => !vessel.features.some(feature => feature.name === "Fouling")).map(v => v.id);
    const diagnostiscFeatureDisabled = this.syncNotification.vessels.filter(vessel => !vessel.features.some(feature => feature.name === "Diagnostics")).map(v => v.id);
    const shapoliFeatureDisabled = this.syncNotification.vessels.filter(vessel => !vessel.features.some(feature => feature.name === "Shapoli")).map(v => v.id);
    const indexListWithDisabledFeature: number[] = [];
    this.syncNotification.conditions.forEach((c, index) => {
      if (foulingFeatureDisabled.includes(c.vesselId) && (c.conditionType.includes("SpeedLoss") || c.conditionType.includes("FuelConsumption"))) {
        indexListWithDisabledFeature.push(index);
      } else if (diagnostiscFeatureDisabled.includes(c.vesselId) && c.conditionType.includes("PerformanceStatus")) {
        indexListWithDisabledFeature.push(index);
      } else if (shapoliFeatureDisabled.includes(c.vesselId) && c.conditionType === "ShapoliPowerReserveBreach") {
        indexListWithDisabledFeature.push(index);
      }
    });

    for (var i = indexListWithDisabledFeature.length - 1; i >= 0; i--) {
      this.syncNotification.conditions.splice(indexListWithDisabledFeature[i], 1);
    }
  }

  removeLogDataExpressionsDuplicates(conditions: Condition[]): Condition[] {
    const conditionList: Condition[] = JSON.parse(JSON.stringify(conditions));
    conditionList.forEach((c, index) => {
      const duplicationIndex = conditions.findIndex(
        (con, i) => con.vesselId === c.vesselId && index !== i && (con.conditionType === "LogDataExpression" || con.conditionType === "LogDataMathExpression")
      );
      if (duplicationIndex !== -1) {
        if (conditionList[duplicationIndex]?.content == null) conditionList.splice(duplicationIndex, 1);
        if (c.content == null && (c.conditionType === "LogDataExpression" || c.conditionType === "LogDataMathExpression")) conditionList.splice(index, 1);
      }
    });
    return conditionList;
  }

  setInitialData(): void {
    if (!this.syncNotification.conditions.length) return;
    this.step = this.conditionStep;
    const logDataMathThresholds = this.syncNotification.conditions.find(cond => cond.conditionType === "LogDataMathExpression")?.thresholds;
    const logDataThresholds = this.syncNotification.conditions.find(cond => cond.conditionType === "LogDataExpression")?.thresholds;
    this.speedLossPercentage = {
      warning: this.syncNotification.conditions.find(cond => cond.conditionType === "SpeedLossWarning")?.content ?? 0,
      critical: this.syncNotification.conditions.find(cond => cond.conditionType === "SpeedLossCritical")?.content ?? 0,
    };
    this.fuelConsumptionValue = {
      warning: this.syncNotification.conditions.find(cond => cond.conditionType === "FuelConsumptionWarning")?.content ?? 0,
      critical: this.syncNotification.conditions.find(cond => cond.conditionType === "FuelConsumptionCritical")?.content ?? 0,
    };
    this.thresholds = {
      VesselDataOverdueThresholds: this.syncNotification.conditions.find(cond => cond.conditionType === "VesselDataOverdue")?.thresholds ?? [],
      LogDataExpressionThresholds: logDataThresholds ?? logDataMathThresholds ?? [],
      LogDataMathExpressionThresholds: logDataMathThresholds ?? logDataThresholds ?? [],
      ManualInputValueStaleThresholds: this.syncNotification.conditions.find(cond => cond.conditionType === "ManualInputValueStale")?.thresholds ?? [],
      PerformanceStatusNotOkThresholds: this.syncNotification.conditions.find(cond => cond.conditionType === "PerformanceStatusNotOk")?.thresholds ?? [],
      PerformanceStatusObserveThresholds: this.syncNotification.conditions.find(cond => cond.conditionType === "PerformanceStatusObserve")?.thresholds ?? [],
      SpeedLossWarningThresholds: this.syncNotification.conditions.find(cond => cond.conditionType === "SpeedLossWarning")?.thresholds ?? [],
      SpeedLossCriticalThresholds: this.syncNotification.conditions.find(cond => cond.conditionType === "SpeedLossCritical")?.thresholds ?? [],
      FuelConsumptionWarningThresholds: this.syncNotification.conditions.find(cond => cond.conditionType === "FuelConsumptionWarning")?.thresholds ?? [],
      FuelConsumptionCriticalThresholds: this.syncNotification.conditions.find(cond => cond.conditionType === "FuelConsumptionCritical")?.thresholds ?? [],
      ShapoliPowerReserveBreachThresholds: this.syncNotification.conditions.find(cond => cond.conditionType === "ShapoliPowerReserveBreach")?.thresholds ?? [],
      VoyageCompleteThresholds: this.syncNotification.conditions.find(cond => cond.conditionType === "VoyageComplete")?.thresholds ?? [],
      VariableOfflineThresholds: this.syncNotification.conditions.find(cond => cond.conditionType === "VariableOffline")?.thresholds ?? [],
      MissingSignalThresholds: this.syncNotification.conditions.find(cond => cond.conditionType === "MissingSignal")?.thresholds ?? [],
    };
    this.selectedVesselsNameString = this.syncNotification.vessels?.map(vessel => vessel.name).join(", ") ?? "";

    //  set channels
    if (this.isEditMode) {
      this.selectedChannels = this.commonSelectedEmails;
      this.selectedUsers = this.commonSelectedUsers;
      if (this.syncNotification?.vessels?.length) this.syncNotification.vessels.forEach(async v => await LogVariables.fetchAllLogVariablesByVesselId(v.id));

      //  set ManualInputValueStale
      if (!this.manualInputStaleTypeConditions.length) return;
      this.selectedManualInputVariable = {
        text: this.manualInputStaleTypeConditions[0].targetKey === "property:draft" ? "Draft" : "Fuel properties",
        type: this.manualInputStaleTypeConditions[0].targetKey,
      };
    }
  }

  // @Hooks
  mounted(): void {
    this.setInitialData();
    this.notifyShaPoLiEvent = Boolean(this.syncNotification.conditions.filter(cond => cond.conditionType === "ShapoliPowerReserveBreach").length);
    this.notifyVoyageEvent = Boolean(this.syncNotification.conditions.filter(cond => cond.conditionType === "VoyageComplete").length);
    this.notifyVariableOfflineEvent = Boolean(this.syncNotification.conditions.filter(cond => cond.conditionType === "VariableOffline").length);
    this.notifyMissingSignalEvent = Boolean(this.syncNotification.conditions.filter(cond => cond.conditionType === "MissingSignal").length);
  }
}
