

























































import { Component, Vue, Prop, PropSync } from "vue-property-decorator";
import durationHelper from "Utilities/duration-helper";
// types
import { Threshold } from "@/types/condition";

@Component({
  components: {},
})
export default class SeveritySelector extends Vue {
  @PropSync("thresholds") syncThresholds!: Threshold[];
  @Prop({ default: false }) disabled!: boolean;

  // @Data
  staticThresholds = [
    {
      durationInSeconds: 3600,
      severity: "Warning",
    },
    {
      durationInSeconds: 3600,
      severity: "Critical",
    },
  ] as Threshold[];
  selectedThresholds = [] as Threshold[];
  unitCritical = "hours";
  unitWarning = "hours";
  units = ["minutes", "hours", "days"];
  isDataSetted = false;

  // @Methods
  severityColor(severity: string): string {
    return severity === "Critical" ? "error" : "warning";
  }

  durationUnitFromSeconds(seconds: number): string {
    return durationHelper.getDurationUnitFromSeconds(seconds);
  }

  durationValueFromSeconds(seconds: number): string {
    return durationHelper.getDurationValueFromSeconds(seconds);
  }

  onDurationInput($value: number, threshold: Threshold): void {
    if (Number($value) === 0) {
      $value = 1;
      (this.$refs[`duration${threshold.severity}`] as any)[0].internalValue = $value;
    }
    const unit = threshold.severity === "Critical" ? this.unitCritical : this.unitWarning;
    Vue.set(threshold, "durationInSeconds", durationHelper.getSecondsFromDurationValueAndUnit($value, unit));
    const syncThresholdIndex = this.syncThresholds.findIndex(thrl => thrl.severity === threshold.severity);
    if (syncThresholdIndex !== -1) Vue.set(this.syncThresholds[syncThresholdIndex], "durationInSeconds", durationHelper.getSecondsFromDurationValueAndUnit($value, unit));
  }

  numericFilter(event: any): void {
    event = event ? event : window.event;
    const expect = event.target.value.toString() + event.key.toString();
    return !/^\d+$/.test(expect) ? event.preventDefault() : false;
  }

  onUnitsChanged($unit: string, threshold: Threshold): void {
    const thresholdDurationInputValue = (this.$refs[`duration${threshold.severity}`] as any)[0].$refs.input._value;
    threshold.severity === "Critical" ? (this.unitCritical = $unit) : (this.unitWarning = $unit);
    Vue.set(threshold, "durationInSeconds", durationHelper.getSecondsFromDurationValueAndUnit(thresholdDurationInputValue, $unit));
    const syncThresholdIndex = this.syncThresholds.findIndex(thrl => thrl.severity === threshold.severity);
    if (syncThresholdIndex !== -1) Vue.set(this.syncThresholds[syncThresholdIndex], "durationInSeconds", durationHelper.getSecondsFromDurationValueAndUnit(thresholdDurationInputValue, $unit));
  }

  setTimeUnit(threshold: Threshold): string {
    return durationHelper.getDurationUnitFromSeconds(threshold.durationInSeconds);
  }

  setInitialData(): void {
    this.staticThresholds = [
      {
        durationInSeconds: this.syncThresholds.find(thr => thr.severity === "Warning")?.durationInSeconds ?? 3600,
        severity: "Warning",
        notificationConditionId: this.syncThresholds.find(thr => thr.severity === "Warning")?.notificationConditionId ?? 0,
      },
      {
        durationInSeconds: this.syncThresholds.find(thr => thr.severity === "Critical")?.durationInSeconds ?? 3600,
        severity: "Critical",
        notificationConditionId: this.syncThresholds.find(thr => thr.severity === "Critical")?.notificationConditionId ?? 0,
      },
    ] as Threshold[];
    this.unitWarning = durationHelper.getDurationUnitFromSeconds(this.staticThresholds[0].durationInSeconds);
    this.unitCritical = durationHelper.getDurationUnitFromSeconds(this.staticThresholds[1].durationInSeconds);
    this.isDataSetted = true;
  }

  //  @Hoods
  mounted(): void {
    this.setInitialData();
  }
}
