var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isDisplayed)?_c('div',{staticClass:"custom-recipient-item d-flex align-center py-2"},[_c('div',{staticClass:"vessel-name text-truncate font-weight-bold",staticStyle:{"width":"200px"}},[_vm._v(" "+_vm._s(_vm.vesselName)+" ")]),_c('div',{staticClass:"event text-truncate",staticStyle:{"width":"320px"}},[_vm._v(" "+_vm._s(_vm.getConditionTypeText)+" ")]),_c('div',{staticClass:"channels"},[_c('v-select',{staticClass:"recipient-select elevation-0",staticStyle:{"width":"700px"},attrs:{"items":_vm.syncRecipients,"item-text":"email","return-object":"","hide-details":"","dense":"","outlined":"","multiple":"","chips":""},on:{"change":_vm.onRecipientsChanged},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"my-1"},[_vm._v(_vm._s(item.userId ? _vm.userName(item.userId) : item.email))])]}},{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"ripple":false,"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_vm._v(" "+_vm._s(item.userId ? _vm.userName(item.userId) : item.email)+" ")])],1)],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}],null,false,3642979932),model:{value:(_vm.selectedRecipients),callback:function ($$v) {_vm.selectedRecipients=$$v},expression:"selectedRecipients"}})],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }